<template>
	<div class="waybill_box">
		<jy-query ref="checkForm" :model="formData">
			<jy-query-item prop="routeId" label="线路组织:">
				<div @click="selectInstitutions">
					<!-- <el-input placeholder="请选择" v-model="formData.routeName"></el-input> -->
					<el-select v-model="formData.routeId" placeholder="请选择线路">
						<el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
						</el-option>
					</el-select>
				</div>
			</jy-query-item>
			<jy-query-item label="车牌号:" prop="vehicleNo">
				<el-input v-model="formData.vehicleNo" placeholder="请输入"></el-input>
			</jy-query-item>
			<jy-query-item label="司机:" prop="driverName">
				<el-input v-model="formData.driverName" placeholder="请输入"></el-input>
			</jy-query-item>
			<jy-query-item label="审核状态:" prop="auditState">
				<el-select v-model="formData.auditState" placeholder="请选择">
					<el-option v-for="item in status" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="跨线路:" prop="isScheduleShift">
				<el-select v-model="formData.isScheduleShift" placeholder="请选择" filterable>
					<el-option v-for="item in ScheduleShiftList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="运行情况:" prop="operateState">
				<el-select v-model="formData.operateState" placeholder="请选择" filterable :disabled="isTrue">
					<el-option v-for="item in states" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="日期:" prop="date">
				<el-date-picker v-model="formData.date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions"></el-date-picker>
			</jy-query-item>
			<template slot="rightCol">
				<el-button v-if="btnexist('list')" type="primary" @click="oncheck">查询</el-button>
				<el-button v-if="btnexist('reset')" type="primary" @click="resetForm('checkForm')">重置</el-button>
			</template>
		</jy-query>
		<div class="jy_Tabs">
			<el-tabs v-model="activeName" @tab-click="tabClick">
				<!-- <el-tab-pane
                    :label="one.label"
                    :name="one.name"
                    v-for="one in tabList"
                    v-bind:key="one.name"
                >
                    <keep-alive>
                        <router-view :ref="one.name" />
                    </keep-alive>
                </el-tab-pane>-->
				<el-tab-pane label="营运班次补录" name="waybillCollection">
					<way-bill ref="waybillCollection" :formData="formData"></way-bill>
				</el-tab-pane>
				<el-tab-pane label="工作时长补录" name="workTimeCollection">
					<work-time ref="workTimeCollection" :formData="formData"></work-time>
				</el-tab-pane>
				<el-tab-pane label="非营运补录" name="noOperatingDivisionsCollection">
					<no-operating ref="noOperatingDivisionsCollection" :formData="formData"></no-operating>
				</el-tab-pane>
				<el-tab-pane label="定点专线补录" name="fixedLineCollection">
					<fixed-line ref="fixedLineCollection" :formData="formData"></fixed-line>
				</el-tab-pane>
			</el-tabs>
		</div>
		<institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择线路组织" :option="index" :defaultProps="props"></institutions-tree>
		<dirctor-show ref="dirctorShow"></dirctor-show>
	</div>
</template>
<script>
	const states = [
		{
			label: "全部",
			value: "",
		},
		{
			label: "未发车",
			value: "0",
		},
		{
			label: "已发车",
			value: "1",
		},
		{
			label: "已完成",
			value: "2",
		},
	];
	const status = [
		{
			value: "0",
			label: "全部",
		},
		{
			value: "1",
			label: "已审核",
		},
		{
			value: "2",
			label: "未审核",
		},
	];
	import fixedLine from "./fixedLineCollection.vue";
	import noOperating from "./noOperatingDivisionsCollection.vue";
	import wayBill from "./waybillCollection.vue";
	import workTime from "./workTimeCollection.vue";

	import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
	import dirctorShow from "@/components/pages/admin/waybillManagement/dirctorShow";

	import { btnMixins } from "@/common/js/button.mixin";
	export default {
		data() {
			return {
				props: {
					children: "children",
					label: "text",
				},
				isTrue: false, //运行情况禁用
				index: {
					index: 0,
				},
				plateList: [],
				driverList: [],
				ScheduleShiftList: [
					{
						value: "",
						label: "全部",
					},
					{
						value: "1",
						label: "是",
					},
					{
						value: "0",
						label: "否",
					},
				],
				states,
				formData: {
					routeId: "",
					routeName: "",
					date: "",
					vehicleNo: "",
					driverName: "",
					checked: false,
					isScheduleShift: "",
					operateState: "",
					auditState: "0",
				},
				activeName: "waybillCollection",
				oldActiveName: "waybillCollection",
				tabList: [],
				status,
				pickerOptions: {
					cellClassName(date) {
						//已完成行车，但未进行备注的日期
						if (date.Format("yyyy-MM-dd") == "1082-10-10") {
							return "changered";
						}
					},
				},
				routeOptions: [],

				btnRoute: "/admin/waybillManagement/collection",
			};
		},
		mixins: [btnMixins],
		components: {
			institutionsTree,
			dirctorShow,
			fixedLine,
			noOperating,
			wayBill,
			workTime,
		},
		activated() {
			this.setActeiveName();
			this.getRouteList();
		},
		created() {
			this.getDate();
			this.setActeiveName();
			this.getRouteList();
		},
		watch: {
			$route: function () {
				this.setActeiveName();
			},
			"formData.routeName": function (val, oldVal) {
				if (val != oldVal) {
					if (this.formData.routeName == "") {
						this.formData.routeId = "";
					}
				}
			},
			activeName: function (val) {
				if (
					this.$route.name == "collection" ||
					this.$route.name == "collectionDefault"
				) {
					this.oldActiveName = val;
				}
				if (val == "workTimeCollection") {
					this.formData.operateState = "";
					this.isTrue = true;
				} else {
					this.isTrue = false;
				}
			},
		},
		methods: {
			getRouteList() {
				let url = "/baseinforoute/queryCurrentAuthorityRoute";
				this.$http.post(url).then(({ detail }) => {
					this.routeOptions = detail;
				});
			},
			// 查询默认当天
			getDate() {
				var date = new Date();
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var day = date.getDate();
				if (month < 10) {
					month = "0" + month;
				}
				if (day < 10) {
					day = "0" + day;
				}
				var nowDate = year + "-" + month + "-" + day;
				this.formData.date = nowDate;
			},
			setActeiveName() {
				let route = this.$route;
				if (route.query && route.query.type) {
					this.activeName = route.query.type;
				} else {
					this.activeName = this.oldActiveName;
				}
			},
			dictionShow() {
				this.$refs.dirctorShow.init();
			},
			// 组织
			selectInstitutions() {
				this.$refs.institutions.init();
			},
			addInstitutions(data) {
				this.formData.routeId = data.id;
				this.formData.routeName = data.text;
				// this.oncheck()
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			oncheck() {
				let ref = this.$refs[this.activeName];
				ref.getList();
			},
			tabClick(e) {
				let url = "/admin/waybillManagement/collection";
				this.$router.replace({ path: url, query: { type: e.name } });
			},
		},
	};
</script>
<style lang='scss'>
	.changered span {
		color: red;
	}
	.waybill_box {
		.el-table {
			.el-table__fixed {
				// 左固定列
				height: auto !important;
				bottom: 16px; // 改为自动高度后，设置与父容器的底部距离，高度会动态改变，值可以设置比滚动条的高度稍微大一些
			}
			.el-table__fixed-right {
				// 右固定列
				height: auto !important;
				bottom: 16px; // 改为自动高度后，设置与父容器的底部距离，高度会动态改变，值可以设置比滚动条的高度稍微大一些
			}
		}
	}
</style>
