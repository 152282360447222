<template>
    <div class="batchUpdateWaybill">
        <jy-dialog type="form"
            :title="title"
            :visible.sync="dialogVisible"
            custom-class="dialog_form"
            :width="global.dialogWidth"
        >
            <el-tabs v-model="activeName">
                <el-tab-pane label="替换车辆" name="first">
                    <div>
                        <el-form :model="diaform" ref="mmdialogForm">
                            <el-form-item
                                label="是否跨线路:"
                                prop="isScheduleShift"
                                :label-width="formLabelWidth"
                            >
                                <el-radio v-model="diaform.isScheduleShift" label="1">是</el-radio>
                                <el-radio v-model="diaform.isScheduleShift" label="0">否</el-radio>
                            </el-form-item>
                            <el-form-item
                                label="车牌号:"
                                prop="vehicleNo"
                                :label-width="formLabelWidth"
                            >
                                <div v-if="!checked">
                                    <el-input
                                        placeholder="请选择"
                                        v-model="diaform.vehicleNo"
                                        @focus="selectInstitutions('car')"
                                    ></el-input>
                                </div>
                                <div v-else>
                                    <el-input
                                        v-model="diaform.vehicleNo"
                                        placeholder="请输入"
                                        clearable
                                        @focus="carSelect"
                                    ></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item
                                label="备注:"
                                prop="operateRemark"
                                :label-width="formLabelWidth"
                            >
                                <el-input
                                    v-model="diaform.operateRemark"
                                    type="textarea"
                                    :rows="4"
                                    placeholder="请输入内容"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="jy_explain">注:若为空或者不填则不修改</div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="替换司机" name="second">
                    <div>
                        <el-form :model="diaform" ref="qtdialogForm">
                            <el-form-item
                                label="是否跨线路:"
                                prop="isScheduleShift"
                                :label-width="formLabelWidth"
                            >
                                <el-radio v-model="diaform.isScheduleShift" label="1">是</el-radio>
                                <el-radio v-model="diaform.isScheduleShift" label="0">否</el-radio>
                            </el-form-item>
                            <el-form-item
                                label="司机:"
                                prop="driverName"
                                :label-width="formLabelWidth"
                            >
                                <div @click="selectInstitutions('driver')" v-if="!checked">
                                    <el-input placeholder="请选择" v-model="diaform.driverName"></el-input>
                                </div>
                                <div @click="siteSelect" v-else>
                                    <el-input
                                        v-model="diaform.driverName"
                                        placeholder="请输入"
                                        clearable
                                    ></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item
                                label="班次折算:"
                                prop="shiftConversion"
                                :label-width="formLabelWidth"
                            >
                                <el-input v-model="diaform.shiftConversion" clearable></el-input>
                            </el-form-item>
                            <el-form-item
                                label="备注:"
                                prop="operateRemark"
                                :label-width="formLabelWidth"
                            >
                                <el-input
                                    v-model="diaform.operateRemark"
                                    type="textarea"
                                    :rows="4"
                                    placeholder="请输入内容"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="jy_explain">注:若为空或者不填则不修改</div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="dialog-footer" slot="footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">保 存</el-button>
            </div>
        </jy-dialog>
        <cardriver-select ref="siteSelectDialog" @updateSite="onSite"></cardriver-select>
        <depotcar-select ref="carSelectDialog" @updateSite="onCarSite"></depotcar-select>
        <institutions-tree
            ref="institutions"
            @addInstitutions="addInstitutions"
            url="/waybillOperate/getTree"
            title="选择线路组织"
            :defaultProps="props"
        ></institutions-tree>
    </div>
</template>
<script>
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import cardriverSelect from '@/components/pages/admin/resourcesAllocation/carDriver/cardriverSelect'
import depotcarSelect from '@/components/pages/admin/resourcesAllocation/depotCar/depotcarSelect'

export default {
  data() {
    return {
      type: '',
      isCheck: false,
      title: '',
      lineList: [],
      plateList: [],
      driverList: [],
      stationList: [],
      selectInstitutionstype: 'car',
      checked: false,
      props: {
        children: 'children',
        label: 'text'
      },
      activeName: 'first',
      diaform: {
        driverId: '',
        driverName: '',
        vehicleId: '',
        vehicleNo: '',
        isScheduleShift: '',
        shiftConversion: '',
        checked: false,
        operateRemark: ''
      },
      batchdeleteList: [],
      dialogVisible: false,
      formLabelWidth: '100px'
    }
  },
  components: {
    institutionsTree,
    cardriverSelect,
    depotcarSelect
  },
  activated() {},
  created() {},
  watch: {
    type: function(val) {
      let t = ''
      let isCheck = false
      switch (val) {
        case 'batch':
          t = '批量修改路单'
          break
        case 'changeCar':
          t = '选择实际车牌号'
          break
        case 'changeDriver':
          t = '选取实际司机'
          break
        default:
          t = '批量修改路单'
      }
      this.isCheck = isCheck
      this.title = t
    },
    dialogVisible(newview) {
      if (!newview) {
        this.resetForm('mmdialogForm')
        this.resetForm('qtdialogForm')
      }
    },
    'diaform.isScheduleShift': function(val) {
      if (val == 1) {
        this.checked = true
      } else {
        this.checked = false
      }
    }
  },
  methods: {
    init(option, type) {
      this.type = type ? type : 'batch'
      this.dialogVisible = true
      if (option) {
        this.$nextTick(() => {
          this.batchdeleteList = JSON.parse(JSON.stringify(option))
        })
      }
    },
    // 组织
    selectInstitutions(type) {
      this.selectInstitutionstype = type
      this.$refs.institutions.init()
    },
    addInstitutions(data) {
      console.log(data)
      if (this.selectInstitutionstype == 'car') {
        this.diaform.vehicleId = data.id
        this.diaform.vehicleNo = data.text
      } else if (this.selectInstitutionstype == 'driver') {
        this.diaform.driverId = data.id
        this.diaform.driverName = data.text
      }
    },
    // 车辆选择
    carSelect() {
      this.$refs.carSelectDialog.init()
    },
    onCarSite(siteArr) {
      // 选择的车辆及对应的VIN显示
      console.log(siteArr)
      siteArr = JSON.parse(JSON.stringify(siteArr))
      this.diaform.vehicleNo = siteArr.vehicleNo
      this.diaform.vehicleId = siteArr.vehicleId
    },
    // 司机选择
    siteSelect() {
      this.$refs.siteSelectDialog.init()
    },
    onSite(siteArr) {
      // 选择的车辆及对应的VIN显示
      siteArr = JSON.parse(JSON.stringify(siteArr))
      console.log(siteArr)
      this.diaform.driverName = siteArr.name
      this.diaform.driverId = siteArr.driverId
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancel() {
      this.dialogVisible = false
      console.log('cancel')
    },
    save() {
      let ids = []
      this.batchdeleteList.forEach(item => {
        ids.push(item.waybillId)
      })
      let option = {
        ...this.diaform,
        ids: ids
      }
      console.log(option)
      let url = '/waybillOperate/batchUpdate'
      this.$http.post(url, option).then(res => {
        this.$message({
          type: 'success',
          message: res.msg
        })
        this.dialogVisible = false
        this.$emit('updateList')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.jy_explain {
  margin-left: 100px;
}
</style>