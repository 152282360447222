<template>
    <div class="shiftAdjust">
        <jy-dialog type="form"
            :title="title"
            :visible.sync="dialogVisible"
            custom-class="dialog_form"
            :width="global.dialogWidth"
        >
            <div>
                <el-form
                    :model="diaform"
                    ref="dialogForm"
                    :disabled="isCheck"
                    :label-width="formLabelWidth"
                >
                    <el-form-item label="调整类型:" prop="shiftStatus">
                        <el-radio v-model="diaform.shiftStatus" label="4">砍班</el-radio>
                        <el-radio v-model="diaform.shiftStatus" label="5">脱班</el-radio>
                    </el-form-item>
                    <el-form-item label="调整原因:" prop="adjReason">
                        <!-- <el-select v-model="diaform.adjReason" placeholder="请选择">
                            <el-option
                                v-for="item in reasonList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>-->
                        <el-input v-model="diaform.adjReason" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="备注:" prop="operateRemark">
                        <el-input
                            v-model="diaform.operateRemark"
                            type="textarea"
                            :rows="4"
                            placeholder="请输入内容"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="!isCheck">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="save('dialogForm')">确 定</el-button>
                </div>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
  data() {
    return {
      type: '',
      isCheck: false,
      title: '',
      reasonList: [],
      directionList: [
        {
          value: 1,
          label: '未知'
        }
      ],
      stationList: [],
      props: {
        children: 'children',
        label: 'text'
      },
      diaform: {
        ids: '',
        shiftStatus: '',
        adjReason: '',
        operateRemark: ''
      },
      batchdeleteList: [],
      dialogVisible: false,
      formLabelWidth: '100px'
    }
  },
  components: {},
  activated() {
    //   this.getReason()
  },
  created() {},
  watch: {
    type: function(val) {
      let t = ''
      let isCheck = false
      switch (val) {
        case 'batch':
          t = '批量班次调整'
          break
        case 'single':
          t = '班次调整'
          break
        case 'view':
          t = '查看班次'
          isCheck = true
          break
        default:
          t = '批量班次调整'
      }
      this.isCheck = isCheck
      this.title = t
    },
    dialogVisible(newview) {
      if (!newview) {
        this.resetForm('dialogForm')
      }
    }
  },
  methods: {
    init(option, type) {
      this.type = type ? type : 'batch'
      this.dialogVisible = true
      if (option) {
        this.$nextTick(() => {
          if (this.type == 'batch') {
            this.batchdeleteList = JSON.parse(JSON.stringify(option))
          } else {
            let sOption = JSON.parse(JSON.stringify(option))
            this.diaform.shiftStatus = sOption.shiftStatus
            this.diaform.adjReason = sOption.adjReason
            this.diaform.operateRemark = sOption.operateRemark
            this.diaform.ids = [sOption.waybillId]
          }
        })
      }
    },
    // 调整原因
    getReason() {
      let url = '/baseinfodriver/list'
      this.$http.post(url).then(({ detail }) => {
        this.reasonList = detail
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancel() {
      this.dialogVisible = false
      console.log('cancel')
    },
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let option = {}
          if (this.type == 'batch') {
            let ids = []
            this.batchdeleteList.forEach(item => {
              ids.push(item.waybillId)
            })
            option = {
              ...this.diaform,
              ids: ids
            }
          } else {
            option = this.diaform
          }
          console.log(option)
          let url = '/waybillOperate/batchAdjust'
          this.$http.post(url, option).then(res => {
            this.$message({
              type: 'success',
              message: res.msg
            })
            this.dialogVisible = false
            this.$emit('updateList')
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>