<template>
	<div class="addCollection">
		<jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_table" :width="global.dialogTabelWidth">
			<div>
				<el-form :model="diaform" ref="dialogForm" :rules="rules" :disabled="isCheck" :label-width="formLabelWidth">
					<el-row>
						<el-col :span="12">
							<el-form-item label="线路组织:" prop="routeName">
								<div>
									<el-input placeholder="请选择" v-model="diaform.routeName" :disabled="isUpdate" @focus="selectInstitutions"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="是否跨线路:" prop="isScheduleShift">
								<el-radio v-model="diaform.isScheduleShift" label="1">是</el-radio>
								<el-radio v-model="diaform.isScheduleShift" label="0">否</el-radio>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<!-- :disabled="isUpdate" -->
							<el-form-item label="车牌号:" prop="actualVehicleNo">
								<div v-if="!checked">
									<el-input placeholder="请选择" v-model="diaform.actualVehicleNo" @focus="selectcarDriversTree"></el-input>
								</div>
								<div v-else>
									<el-input v-model="diaform.actualVehicleNo" placeholder="请输入" clearable @focus="carSelect"></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="计划车牌号:" prop="planVehicleNo">
								<el-input v-model="diaform.planVehicleNo" clearable :disabled="true"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="司机:" prop="actualDriverName">
								<div v-if="!checked">
									<el-input placeholder="请选择" @focus="selectDriversTree" v-model="diaform.actualDriverName"></el-input>
								</div>
								<div @click="siteSelect" v-else>
									<el-input v-model="diaform.actualDriverName" placeholder="请输入" clearable></el-input>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="计划司机:" prop="planDriverName">
								<el-input v-model="diaform.planDriverName" clearable :disabled="true"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="方向:" prop="dir">
								<el-select v-model="diaform.dir" placeholder="请选择" @change="dirChange">
									<el-option v-for="item in directionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="营运日期:" prop="operationDate">
								<el-date-picker v-model="diaform.operationDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :disabled="isUpdate || isCheck"></el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="起始站:" prop="fsSiteId">
								<el-select v-model="diaform.fsSiteId" placeholder="请选择" @change="changeOverSites(true)">
									<el-option v-for="(item,k) in fsstationList" :key="item.siteId+k" :label="item.siteName" :value="item.siteId"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="终点站:" prop="lsSiteId">
								<el-select v-model="diaform.lsSiteId" placeholder="请选择" @change="changeOverSites(false)">
									<el-option v-for="(item,k) in lsstationList" :key="item.siteId+k" :label="item.siteName" :value="item.siteId"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="开始时间:" prop="beginTime">
								<el-time-picker v-model="diaform.beginTime" placeholder="选择时间" value-format="HH:mm" :picker-options="pickerOptions"></el-time-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="结束时间:" prop="endTime">
								<el-time-picker v-model="diaform.endTime" placeholder="选择时间" value-format="HH:mm" :picker-options="pickerOptions"></el-time-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="线路里程:" prop="runMileage">
								<el-input v-model="diaform.runMileage" clearable></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="途经站点数:" prop="overSites">
								<el-input v-model="diaform.overSites" clearable disabled></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item label="班次折算:" prop="shiftConversion">
						<el-input v-model="diaform.shiftConversion" clearable></el-input>
					</el-form-item>
					<el-form-item label="备注:" prop="operateRemark">
						<el-input v-model="diaform.operateRemark" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<div v-if="!isCheck">
					<el-button @click="cancel">取 消</el-button>
					<el-button type="primary" @click="save('dialogForm')">确 定</el-button>
				</div>
			</div>
		</jy-dialog>
		<cardriver-select ref="siteSelectDialog" @updateSite="onSite"></cardriver-select>
		<depotcar-select ref="carSelectDialog" @updateSite="onCarSite"></depotcar-select>
		<institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择线路组织" :option="index" :defaultProps="props"></institutions-tree>
		<institutions-tree ref="carDriversTree" @addInstitutions="addCarDriversTree" url="/waybillOperate/getRouteRevealTree" title="选择车辆" :option="carOption" :isActivated="false" :defaultProps="props"></institutions-tree>
		<institutions-tree ref="driversTree" @addInstitutions="addDriversTree" url="/waybillOperate/getRouteRevealTree" title="选择司机" :option="dirverOption" :isActivated="false" :defaultProps="props"></institutions-tree>
	</div>
</template>
<script>
	import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
	import cardriverSelect from "@/components/pages/admin/resourcesAllocation/carDriver/cardriverSelect";
	import depotcarSelect from "@/components/pages/admin/resourcesAllocation/depotCar/depotcarSelectOne";

	const pickerOptions = {
		format: "HH:mm",
	};
	export default {
		data() {
			return {
				type: "",
				index: {
					index: 0,
				},
				carOption: {
					index: "",
					routeId: "",
				},
				dirverOption: {
					index: "",
					routeId: "",
				},
				isCheck: false,
				isUpdate: false,
				pickerOptions,
				title: "",
				lineList: [],
				plateList: [],
				driverList: [],
				directionList: [
					{
						value: "1",
						label: "上行",
					},
					{
						value: "2",
						label: "下行",
					},
				],
				// 完整站点选择
				stationList: [],
				// 起始站选择
				fsstationList: [],
				// 终点站选择
				lsstationList: [],
				props: {
					children: "children",
					label: "text",
					value: "id",
					// multiple: true,
					checkStrictly: true,
				},
				checked: false,
				diaform: {
					actualDriverId: "",
					actualDriverName: "",
					actualVehicleId: "",
					actualVehicleNo: "",
					routeName: "",
					routeId: "",
					planVehicleId: "",
					planVehicleNo: "",
					planDriverId: "",
					planDriverName: "",
					isScheduleShift: "0",
					checked: false,
					dir: "1",
					fsSiteId: "",
					lsSiteId: "",
					beginTime: "",
					endTime: "",
					runMileage: "",
					overSites: "",
					operationDate: new Date().Format("yyyy-MM-dd"),
					shiftConversion: "",
					operateRemark: "",
				},
				rules: {
					routeName: [
						{
							required: true,
							message: "请选取线路组织",
							trigger: ["change"],
						},
					],
					actualVehicleNo: [
						{
							required: true,
							message: "请选取车牌",
							trigger: ["change"],
						},
					],
					actualDriverName: [
						{
							required: true,
							message: "请选取司机",
							trigger: ["change"],
						},
					],
					dir: [
						{
							required: true,
							message: "请选取方向",
							trigger: ["change"],
						},
					],
					fsSiteId: [
						{
							required: true,
							message: "请选取起始站",
							trigger: "change",
						},
					],
					lsSiteId: [
						{
							required: true,
							message: "请选取终点站",
							trigger: "change",
						},
					],
					operationDate: [
						{
							required: true,
							message: "请选取营运日期",
							trigger: "change",
						},
					],
					beginTime: [
						{
							required: true,
							message: "请选取开始时间",
							trigger: "change",
						},
					],
					endTime: [
						{
							required: true,
							message: "请选取结束时间",
							trigger: "change",
						},
					],
					runMileage: [
						{
							required: true,
							message: "请填写线路里程",
							trigger: "blur",
						},
					],
					overSites: [
						{
							required: true,
							message: "请填写途经站点数",
							trigger: "blur",
						},
					],
					shiftConversion: [
						{
							required: true,
							message: "请填写班次折算",
							trigger: "blur",
						},
					],
				},
				dialogVisible: false,
				formLabelWidth: "180px",
			};
		},
		components: {
			institutionsTree,
			cardriverSelect,
			depotcarSelect,
		},
		activated() {},
		created() {},
		watch: {
			type: function (val) {
				let t = "";
				let isCheck = false;
				let isUpdate = false;
				switch (val) {
					case "add":
						t = "补录路单";
						break;
					case "update":
						t = "修改路单";
						isUpdate = true;
						break;
					case "view":
						t = "查看路单";
						isCheck = true;
						break;
					default:
						t = "补录路单";
				}
				this.isCheck = isCheck;
				this.isUpdate = isUpdate;
				this.title = t;
			},
			dialogVisible(newview) {
				if (!newview) {
					this.resetForm("dialogForm");
					this.diaform.actualVehicleId = "";
					this.lineList = [];
					this.plateList = [];
					this.driverList = [];
					this.fsstationList = [];
					this.lsstationList = [];
					this.stationList = [];
				}
			},
			"diaform.isScheduleShift": function (val) {
				if (val == 1) {
					this.checked = true;
				} else {
					this.checked = false;
				}
			},
			"diaform.routeId": function (val, oldVal) {
				if (val != oldVal && val) {
					this.carOption = {
						index: 1,
						routeId: val,
					};
					this.dirverOption = {
						index: 2,
						routeId: val,
					};
				}
			},
			// "diaform.fsSiteId"(val, oldVal) {
			// 	if (val != oldVal && val && oldVal) {
			// 		console.log(val);
			// 		console.log(this.lsstationList);
			// 		let l = [];
			// 		let n = "";
			// 		this.stationList.forEach((item) => {
			// 			if (item.siteId == val) {
			// 				n = item.order;
			// 			}
			// 		});
			// 		this.stationList.forEach((item) => {
			// 			if (item.order > n) {
			// 				l.push(item);
			// 			}
			// 		});
			// 		this.lsstationList = l;
			// 		if (this.diaform.lsSiteId) {
			// 			let nn = "";
			// 			this.stationList.forEach((item) => {
			// 				if (item.siteId == this.diaform.lsSiteId) {
			// 					nn = item.order;
			// 				}
			// 			});
			// 			this.diaform.overSites = nn - n + 1;
			// 		}
			// 	}
			// },
			// "diaform.lsSiteId"(val, oldVal) {
			// 	if (val != oldVal && val && oldVal) {
			// 		console.log(val);
			// 		console.log(this.fsstationList);
			// 		let l = [];
			// 		let n = "";
			// 		this.stationList.forEach((item) => {
			// 			if (item.siteId == val) {
			// 				n = item.order;
			// 			}
			// 		});
			// 		this.stationList.forEach((item) => {
			// 			if (item.order < n) {
			// 				l.push(item);
			// 			}
			// 		});
			// 		this.fsstationList = l;
			// 		if (this.diaform.fsSiteId) {
			// 			let nn = "";
			// 			this.stationList.forEach((item) => {
			// 				if (item.siteId == this.diaform.fsSiteId) {
			// 					nn = item.order;
			// 				}
			// 			});
			// 			this.diaform.overSites = n - nn + 1;
			// 		}
			// 	}
			// },
			"diaform.actualVehicleNo"(val) {
				if (!val) {
					console.log(this.diaform.actualVehicleId);
					this.diaform.actualVehicleId = "";
					console.log(this.diaform.actualVehicleId);
				}
			},
		},
		methods: {
			changeOverSites(isfs) {
				let n = 0,
					nn = 0,
					l = [];
				if (this.diaform.fsSiteId) {
					this.stationList.forEach((item) => {
						if (item.siteId == this.diaform.fsSiteId) {
							nn = item.order;
						}
					});
				}
				if (this.diaform.lsSiteId) {
					this.stationList.forEach((item) => {
						if (item.siteId == this.diaform.lsSiteId) {
							n = item.order;
						}
					});
				}
				if (isfs) {
					this.stationList.forEach((item) => {
						if (item.order > nn) {
							l.push(item);
						}
					});
					this.lsstationList = l;
				} else {
					this.stationList.forEach((item) => {
						if (item.order < n) {
							l.push(item);
						}
					});
					this.fsstationList = l;
				}
				this.diaform.overSites = n - nn + 1;
			},
			init(option, type) {
				this.type = type ? type : "add";
				this.dialogVisible = true;
				if (option) {
					this.$nextTick(() => {
						if (this.type != "add") {
							//更新或查看
							this.diaform = JSON.parse(JSON.stringify(option));
							console.log(this.diaform);
							this.getUpDownSites();
						} else {
							//新增
							let addOption = JSON.parse(JSON.stringify(option));
							this.diaform.routeId = addOption.routeId;
							this.diaform.routeName = addOption.routeName;
						}
					});
				}
			},
			// 起始站点
			getUpDownSites() {
				let option = {
					routeId: this.diaform.routeId,
					goType: this.diaform.dir,
				};
				let url = "/routeRelSite/selectByGoType";
				this.$http.post(url, option).then(({ detail }) => {
					this.stationList = detail;
					this.fsstationList = detail;
					this.lsstationList = detail;
					detail.forEach((one) => {
						if (one.siteId == "9fde002db64c4eb0827b2c2d7f1a3ec01") {
							console.log(one);
						}
					});
				});
			},
			dirChange() {
				this.diaform.fsSiteId = "";
				this.diaform.lsSiteId = "";
				this.getUpDownSites();
				//   if (this.diaform.routeId && this.diaform.dir) {
				//   }
			},
			// 线路组织
			selectInstitutions() {
				this.$refs.institutions.init();
			},
			addInstitutions(data) {
				console.log(data);
				this.diaform.routeId = data.id;
				this.diaform.routeName = data.text;
				//   if (this.diaform.routeId && this.diaform.dir) {
				//       }
				this.getUpDownSites();
				this.diaform.fsSiteId = "";
				this.diaform.lsSiteId = "";
				this.diaform.overSites = "";
			},
			//车辆组织
			selectcarDriversTree(e) {
				$(e.target).blur();
				if (this.carOption.routeId) {
					this.$refs.carDriversTree.init();
				} else {
					this.$alert("请先选择线路", "提示", {
						confirmButtonText: "确定",
						callback: (action) => {
							console.log(action);
						},
					});
				}
			},
			addCarDriversTree(data) {
				this.diaform.actualVehicleId = data.id;
				this.diaform.actualVehicleNo = data.text;
			},
			//司机组织
			selectDriversTree(e) {
				$(e.target).blur();
				if (this.carOption.routeId) {
					this.$refs.driversTree.init();
				} else {
					this.$alert("请先选择线路", "提示", {
						confirmButtonText: "确定",
						callback: (action) => {
							console.log(action);
						},
					});
				}
			},
			addDriversTree(data) {
				this.diaform.actualDriverId = data.id;
				this.diaform.actualDriverName = data.text;
			},
			// 车辆选择
			carSelect() {
				this.$refs.carSelectDialog.init();
			},
			onCarSite(siteArr) {
				// 选择的车辆及对应的VIN显示
				console.log(siteArr);
				siteArr = JSON.parse(JSON.stringify(siteArr));
				this.diaform.actualVehicleNo = siteArr.vehicleNo;
				this.diaform.actualVehicleId = siteArr.vehicleId;
			},
			// 司机选择
			siteSelect() {
				this.$refs.siteSelectDialog.init();
			},
			onSite(siteArr) {
				// 选择的车辆及对应的VIN显示
				siteArr = JSON.parse(JSON.stringify(siteArr));
				console.log(siteArr);
				this.diaform.actualDriverName = siteArr.name;
				this.diaform.actualDriverId = siteArr.driverId;
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			cancel() {
				this.dialogVisible = false;
				console.log("cancel");
			},
			save(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(this.diaform);
						let url = "/waybillOperate/";
						if (this.type == "add") {
							url = url + "add";
						} else {
							url = url + "modify";
						}
						let option = this.diaform;
						this.$http.post(url, option).then((res) => {
							this.$message({
								type: "success",
								message: res.msg,
							});
							this.dialogVisible = false;
							this.$emit("updateList");
						});
					} else {
						return false;
					}
				});
			},
		},
	};
</script>
