<template>
    <div class="batchUpdateWorktime">
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :disabled="isCheck" :label-width="formLabelWidth">
                    <el-form-item label="工作时长:" prop="workingHours">
                        <el-input v-model="diaform.workingHours" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="备注:" prop="operateRemark">
                        <el-input v-model="diaform.operateRemark" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="dialog-footer" slot="footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">保 存</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isCheck: false,
            title: '批量修改工作时长',
            batchdeleteList: [],
            diaform: {
                workingHours: '',
                operateRemark: ''
            },
            dialogVisible: false,
            formLabelWidth: '100px'
        }
    },
    components: {},
    activated() {},
    created() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('dialogForm')
            }
        }
    },
    methods: {
        init(option) {
            this.dialogVisible = true
            if (option) {
                this.$nextTick(() => {
                    this.batchdeleteList = JSON.parse(JSON.stringify(option))
                })
            }
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            let ids = []
            this.batchdeleteList.forEach(item => {
                ids.push(item.workingHoursId)
            })
            let option = {
                ...this.diaform,
                ids: ids
            }
            let url = '/waybillWorkingHours/batchUpdate'
            this.$http.post(url, option).then(res => {
                this.$message({
                    type: 'success',
                    message: res.msg
                })
                this.dialogVisible = false
                this.$emit('updateList')
            })
        }
    }
}

</script>
