<template>
    <div class="batchUpdateWorktime">
        <jy-dialog type="form"
            :title="title"
            :visible.sync="dialogVisible"
            custom-class="dialog_form"
            :width="global.dialogWidth"
        >
            <div>
                <el-form
                    :model="diaform"
                    ref="dialogForm"
                    :disabled="isCheck"
                    :rules="rules"
                    :label-width="formLabelWidth"
                >
                    <el-form-item label="运行里程:" prop="runMileage">
                        <el-input v-model="diaform.runMileage" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="专线名称:" prop="rentRouteName">
                        <div @click="rentrouteSelect">
                            <el-input v-model="diaform.rentRouteName" placeholder="请选择" clearable></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="班次折算:" prop="shiftConversion">
                        <el-input v-model="diaform.shiftConversion" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="备注:" prop="operateRemark">
                        <el-input
                            v-model="diaform.operateRemark"
                            type="textarea"
                            :rows="4"
                            placeholder="请输入内容"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="dialog-footer" slot="footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save('dialogForm')">保 存</el-button>
            </div>
        </jy-dialog>
        <rentroute-select ref="rentrouteDialog" @updateSite="onRentroute"></rentroute-select>
    </div>
</template>
<script>
import rentrouteSelect from '@/components/pages/admin/waybillManagement/collection/fixedLineCollection/rentrouteSelect'
export default {
  data() {
    return {
      isCheck: false,
      title: '批量修改定点专线',
      stationList: [],
      batchdeleteList: [],
      diaform: {
        runMileage: '0',
        shiftConversion: '',
        rentRouteId: '',
        rentRouteName: '',
        operateRemark: ''
      },
      rules: {
        runMileage: [
          {
            required: true,
            message: '请填写GPS里程',
            trigger: 'blur'
          }
        ]
      },
      dialogVisible: false,
      formLabelWidth: '100px'
    }
  },
  components: { rentrouteSelect },
  activated() {
    // this.getNooperatingType()
  },
  created() {},
  watch: {
    dialogVisible(newview) {
      if (!newview) {
        this.resetForm('dialogForm')
      }
    }
  },
  methods: {
    init(option) {
      this.dialogVisible = true
      if (option) {
        this.$nextTick(() => {
          //   this.diaform = JSON.parse(JSON.stringify(option))
          this.batchdeleteList = JSON.parse(JSON.stringify(option))
        })
      }
    },
    // 专线名称
    getNooperatingType() {
      let option = {
        name: '',
        pageIndex: 0,
        pageSize: 0
      }
      let url = '/baserentroute/list'
      this.$http.post(url, option).then(({ detail }) => {
        this.stationList = detail.list
      })
    },
    // 专线名称选择
    rentrouteSelect() {
      this.$refs.rentrouteDialog.init()
    },
    onRentroute(siteArr) {
      siteArr = JSON.parse(JSON.stringify(siteArr))
      this.diaform.rentRouteName = siteArr.name
      this.diaform.rentRouteId = siteArr.rentRouteId
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancel() {
      this.dialogVisible = false
      console.log('cancel')
    },
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let ids = []
          this.batchdeleteList.forEach(item => {
            ids.push(item.waybillId)
          })
          let option = {
            ...this.diaform,
            ids: ids
          }
          console.log(option)
          let url = '/waybillRentSettle/batchUpdate'
          this.$http.post(url, option).then(res => {
            this.$message({
              type: 'success',
              message: res.msg
            })
            this.dialogVisible = false
            this.$emit('updateList')
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>